import Vue from 'vue'
import Router from 'vue-router'
import Home from 'components/home'

import not_active from 'components/not_active'

import PageNotFound from 'components/page_not_found'



import store from '../store'



Vue.use(Router)

const path = process.env.VUE_APP_PATH;



const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		next()
		return
	}
	next('/')
}

const ifAuthenticated = (to, from, next) => {
	if (store.getters.isAuthenticated) {
		next()
		return
	}
	next('/login')
}


const scrollTo = (to, from, next) => {

	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth'
	});
}

const ifComplateAccount = (to, from, next) => {
	if (!store.getters.isProfileLoaded && store.getters.isAuthenticated) {
		store.watch(
			(state, getters) => getters.isProfileLoaded,
			(value) => {
				if (value === true) {
					proceed()
				}
			}
		)
	} else {
		proceed()
	}

	function proceed() {
		if (store.getters.isAuthenticated) {
			if (store.getters.isComplateAccount) {
				next()
				return
			} else {
				next('/completa-registrazione')
			}
		} else {
			next()
			return
		}
	}
}
const hasLogin = (to, from, next) => {
	if (!process.env.VUE_APP_HAS_LOGIN) {
		next('/')
	}
}

const moment = require('moment')

const ifStart = (to, from, next) => {
	if (moment(process.env.VUE_APP_START) > moment()) {
		next('/not_active')
		return
	}
	next()
}
const ifEnded = (to, from, next) => {
	if (moment(process.env.VUE_APP_FINISH) < moment()) {
		next('/')
		return
	}
	next()
}
const checkAge = (to, from, next) => {
	if (localStorage.getItem('checkAge')) {
		next()
		return
	}
	if (process.env.VUE_APP_HAS_CHECKAGE == 'ON') {
		next('/check-age')
	} else {
		next()
	}
}
const haveWinPage = (to, from, next) => {
	if (process.env.VUE_APP_HAS_PLAYPAGE) {
		next()
		return
	}
	next('/')
}
const haveCheckAge = (to, from, next) => {
	if (process.env.VUE_APP_HAS_CHECKAGE) {
		next()
		return
	}
	next('/')
}
const ifTime = (to, from, next) => {
	if (process.env.VUE_APP_HAS_TIME_FOR_PLAY) {
		var hour = moment().format('H')
		var now = moment()
		var start = now.toDate();
		var end = now.toDate();
		start.setHours(0);
		start.setMinutes(0);
		start.setSeconds(0);
		start.setMilliseconds(0);

		end.setHours(parseFloat(process.env.VUE_APP_CLOSE));
		end.setMinutes(59);
		end.setSeconds(59);
		end.setMilliseconds(0);

		console.log('HOUR NOW: ' + hour)
		if (hour >= parseFloat(process.env.VUE_APP_OPEN) && hour < parseFloat(process.env.VUE_APP_CLOSE) + 1) {
			console.log(' CAN PLAY ALL OK ')
			next()
			return
		} else {
			console.log(' CAN`T PLAY')
			next('/cantplay')
		}
	} else {
		next()
	}
}

export default new Router({
	mode: 'history',
	base: path,

	routes: [{
			path: '/',
			name: 'Home',
			component: Home,
			beforeEnter: guards([ifStart, ifComplateAccount, scrollTo]),
		},
		{
			path: '/not_active',
			name: 'not_active',
			component: not_active,
		},
		{
			path: '*',
			name: 'PageNotFound',
			component: PageNotFound,
		},



	],
})





// allows using multiple guards for routes
function guards(guards) {
	return async (to, from, next) => {

		// a flag to discard remaining guards
		let changed = false;

		// handle next for multiple guards
		const mNext = function (value) {
			// prevent calling next after it is already resolved with a value
			if (changed) return;

			// if we have 'value' reslove next with the value and set changed flag
			if (typeof value != 'undefined') {
				changed = true;
				next(value);
			}
		};

		// call guards
		for (let i = 0; i < guards.length; i++) {
			if (changed) break;
			await guards[i](to, from, mNext);
		}

		// move on if none of guards resolved next with a value
		if (!changed) next();

	}
}