

	<template>
	<div class="widthFull min-height-calc">

		<div>
			<img
				src="../../assets/images/header.jpg"
				class="img-fluid widthFull"
			>

		</div>

	</div>
	</div>
</template>


	<script>
	import { mapGetters } from 'vuex';

	import { innerWidthMixin } from '../../mixins/innerWidthMixin';

	const moment = require('moment');

	export default {
		name: 'header_',
		mixins: [innerWidthMixin],
		data() {
			return {
				// vw: window.innerWidth,
				moment: moment,
				finish: process.env.VUE_APP_FINISH,
			};
		},

		methods: {
			playNow: function (val) {
				var _vue = this;
				if (this.vw > 767) {
					/**Pc */
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#how-partecipate', 700, { offset: 30 });
						}, 100);
					}
				} else if (this.vw < 330) {
					/*Iphone 5*/
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#how-partecipate', 700, { offset: -50 });
						}, 100);
					}
				} else {
					/**Mobile */
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#how-partecipate', 700, { offset: -50 });
						}, 100);
					}
				}
			},

			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -125 });
				}, 100);
			},
		},
		mounted() {
			// window.onresize = () => {
			// 	this.vw = window.innerWidth;
			// };
		},
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getPromotions']),
		},
	};
</script>

