<template>
	<div
		class="widthFull  px-3"
		id="notActive"
	>
		<div class="row justify-content-center py-4">
			<div class="col-12 ">
				<h1 class="uppercase text-center color-2 "><span class="Regular"> Concorsi e promozioni </span> <br> <span class="Bold bold-size"> CONCLUSE </span></h1>
			</div>
		</div>

		<div
			class="row justify-content-center px-md-3 px-lg-5"
			v-if="view"
		>

			<div
				class="col-12 col-md-6 py-2 py-lg-4"
				v-for="(promo,index) in contests"
				@key="index"
			>

				<Card
					:promo="promo"
					type="not_active"
					class="mt-3 mt-md-0"
				/>

			</div>
		</div>

		<div
			class="row justify-content-center no-promo"
			v-else
		>
			<h3 class="Bold color-2 pt-3 text-center ">Non sono presenti promozioni concluse</h3>
		</div>

	</div>
</template>


<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex';
	const moment = require('moment');

	import Card from './cards/card.vue';

	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	import { checkPromoMixin } from '../../mixins/checkPromoMixin';

	export default {
		name: 'active',
		components: {
			Card,
		},

		mixins: [sharedEnvMixin, checkPromoMixin],
		data() {
			return {
				contests: '',
				view: false,
				vw: window.innerWidth,
				oldPresent: false,
				moment: moment,
			};
		},
		methods: {
			getContest: async function (status) {
				this.loadingState = true;
				var data = {
					promotion_status: status,
					identifier_promotion: process.env.VUE_APP_IDENTIFIER_PROMOTION,
					HBGRF: process.env.VUE_APP_HBGRF,
					vector_HBGRF: process.env.VUE_APP_ICODE,
				};
				data = this.$encryptByAES(JSON.stringify(data), process.env.VUE_APP_DOGGIE);

				await axios({
					url: process.env.VUE_APP_ROOT_API + 'api/getMyPromo',
					method: 'POST',
					data: {
						data,
					},
					headers: {
						Authorization: 'Bearer ' + process.env.VUE_APP_RABBIT,
						'Content-type': 'application/json',
					},
				})
					.then((resp) => {
						resp.data = this.$decryptByAES(resp.data, process.env.VUE_APP_DOGGIE);
						resp.data = resp.data.message
						for (let i in resp.data) {
							let pass = JSON.parse(resp.data[i].config_promotion);
							resp.data[i].config_promotion = pass;
						}

						this.contests = resp.data;
						this.$track('getContest', 'SUCCESS');
						this.$track('getContest', resp.data);
						this.loadingState = false;
						if (resp.data) {
							if (resp.data.length == 0) {
								this.view = false;
							} else {
								this.view = true;
							}
						}
					})
					.catch((err) => {
						err.response.data = this.$decryptByAES(
							err.response.data,
							process.env.VUE_APP_DOGGIE
						);
						this.$track('getContestActive', 'ERROR');
					});
			},

			handleResize: function () {
				this.vw = window.innerWidth;
			},
		},
		watch: {},
		beforeUpdate: function () {},
		updated() {},
		mounted: function () {
			this.getContest('ENDED');

			window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		computed: {
			...mapGetters([
				'isAuthenticated',
				'authStatus',
				'getPromotions',
				'getOldPromotions',
			]),
		},
	};
</script>
