<template>

	<div class="promo__card">

		<div class="promo__card__inner">
			<div class="promo__card__inner__info">
				<h4 class="py-3  Bold uppercase text-center">valida dal {{moment(promo.start_promotion).format('DD-MM-YYYY')}} al {{moment(promo.end_promotion).format('DD-MM-YYYY')}}</h4>
			</div>

			<div class="promo__card__inner__top">

				<div
					class="promo__card__inner__top__layover"
					v-if="type != 'active'"
				>
					<h1 class="white Bold uppercase promo__card__inner__top__layover__text">Concluso</h1>
				</div>

				<img
					:src="promo.config_promotion.IMMAGINE_HOME"
					class="img-fluid"
				>

				<!-- clikable area for glovo & deliveroo -->
				<template v-if="promo.id_promotion == 20">
					<a class="magickClick1" target="_blank" href="https://urldefense.com/v3/__https:/ufv9.adj.st?adjust_t=4ock7z4&adjust_campaign=IT&adjust_adgroup=CC_IT_ALL_WebButtons&adjust_creative=CC_IT_ALL_WebButtons_CC_202303&adjust_deeplink=glovoapp*3A*2F*2Fopen*3Flink_type*3Dwall_category*26category_name*3DEDS&adjust_fallback=https*3A*2F*2Fglovoapp.com*2F*3Futm_source*3DCC*26utm_medium*3Dreferral*26utm_campaign*3DCC_IT_ALL_WebButtons_CC_202303&adj_redirect_macos=https*3A*2F*2Fglovoapp.com*2F*3Futm_source*3DCC*26utm_medium*3Dreferral*26utm_campaign*3DCC_IT_ALL_WebButtons_CC_202303__;JSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUl!!AV1iHekQR8s!I1JCO9Uw4GzmqmT8ro5cCM21EAlBSckg1iauWSmg-h-F6nYxrEfWGDp6Xmjr135tHhsiG_gt19xx9xJDjYcdKKFEh7uP6ElOL4Hc$"></a>
					<a class="magickClick2" target="_blank" href="https://urldefense.com/v3/__https:/deliveroo.it/it/__;!!AV1iHekQR8s!IALHBWaZpk9vBPU1pSWXFqbsEezZPHC-hd9oCjaRcV55EXEwMJk2OCPLF4prXI886DbgdDQMhI9qeeZAYx-_5Pi6A7EVczQuHsNpfFSUUA$"></a>
				</template>

			</div>
			<div class="promo__card__inner__bottom text-center mt-3">
				<a
					class="btn btn-primary"
					:href="type == 'active' ?  promo.config_promotion.DIRECTORY : promo.config_promotion.REGOLAMENTO "
					:target="type == 'active' ? '_self' : '_blank'"
				> <span v-if="type == 'active'"> scopri di più</span><span v-else> leggi il regolamento</span> </a>
			</div>

		</div>

	</div>

</template>

<script>
	const moment = require('moment');
	export default {
		name: 'card',
		props: ['promo', 'type'],
		data() {
			return {
				moment: moment,
			};
		},
	};
</script>
