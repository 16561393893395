export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SOCIAL = 'AUTH_SOCIAL'
export const AUTH_OK = 'AUTH_OK'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REGISTRATION = 'AUTH_REGISTRATION'
export const AUTH_COMPLATE_REGISTRATION = 'AUTH_COMPLATE_REGISTRATION'
export const COMPLATE_REGISTRATION_SUCCESS = 'COMPLATE_REGISTRATION_SUCCESS'
export const DECRYPT = 'DECRYPT'
export const ENCRYPT = 'ENCRYPT'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'
export const SET_PROMOTION = 'SET_PROMOTION'
export const ACCEPT_REG = 'ACCEPT_REG'


export const FROM_SET = 'FROM_SET'
