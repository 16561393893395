import axios from 'axios';

export const checkPromoMixin = {


  data() {
    return {


    }
  },
  methods: {

    getContest: async function (status) {
      this.loadingState = true;
      var data = {
        // piva: this.profile.piva,
        // cod: this.profile.cod,
        // group: this.profile.groupPh,
        status: status,
        id_platform: process.env.VUE_APP_ID_PLATFORM,
        HBGRF: process.env.VUE_APP_HBGRF,
        vector_HBGRF: process.env.VUE_APP_ICODE,
      };
      data = this.$encryptByAES(JSON.stringify(data), process.env.VUE_APP_DOGGIE);

      await axios({
          url: process.env.VUE_APP_ROOT_API + "api/getContest",
          method: "POST",
          data: {
            data,
          },
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_RABBIT,
            "Content-type": "application/json",
          },
        })
        .then((resp) => {
          resp.data = this.$decryptByAES(resp.data, process.env.VUE_APP_DOGGIE);
          console.log(resp.data);
          for (var i in resp.data) {
            var pass = JSON.parse(resp.data[i].config);
            resp.data[i].config = pass;
          }
          this.contests = resp.data;
          this.$track("getContest", "SUECCESS");
          this.$track("getContest", resp.data);
          if (resp.data) {
            this.length_ = resp.data.length;
            this.count = resp.data.length;

            if (this.count >= 2 && this.vw > 990) {
              this.length_ = 2;
            } else {
              this.length_ = 1;
            }
            if (this.count == 0) {
              this.view = false;
            } else {
              this.view = true;
            }
            this.loadingState = false;
          } else {
            this.loadingState = false;
          }
        })
        .catch((err) => {
          err.response.data = this.$decryptByAES(
            err.response.data,
            process.env.VUE_APP_DOGGIE
          );
          this.$track("getContestActive", "ERROR");
        });
    },

  },

  mounted() {
    console.log('checkPromoMixin')
  },

}
