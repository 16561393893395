export const pageOffsetMixin = {

  data() {
    return {
      scroll: 0,

    };
  },


  methods: {

    updateScroll: function () {
      this.scroll = window.pageYOffset;
    },
  },

  mounted() {

    console.log('pageOffsetMixin')
    window.addEventListener('scroll', this.updateScroll);
  },

}
