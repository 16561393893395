<template>
	<div class="widthFull">

		<nav
			class="navbar navbar-light navbar-expand-lg fixed-top back-3"
			style="z-index: 11"
		>

			<div
				class="sticky_btn"
				to="/"
				v-scroll-to="{ el: '.main', offset: -2000, duration: 600, easing: 'ease-in-out' }"
				v-if="scroll > 200 && vw >= 768"
				title="Torna su"
			>

				<font-awesome-icon
					:icon="['fa', 'arrow-alt-circle-up']"
					class="icon arrow_icon"
				/>
			</div>

			<div class="container-fluid widthFull">
				<div class="navbar-header">
					<router-link
						to="/"
						v-scroll-to="{ el: '.main', offset: -2000 }"
						class="navbar-brand router-link-exact-active router-link-active"
					>
						<img
							v-if="vw > 768"
							src="../../assets/images/logo.png"
							alt=""
							class="img-fluid logo"
							ref="mainNavLogo"
						/>

						<img
							v-else
							src="../../assets/images/logo_sm.png"
							alt=""
							class="img-fluid logo"
							ref="mainNavLogo"
						/>

					</router-link>

				</div>

				<button
					v-if="!(this.moment(start) > moment())"
					class="navbar-toggler navbar-toggler-right align-self-center mt-0"
					type="button"
					data-toggle="collapse"
					data-target="#navbarCollapse"
				>
					<span class="navbar-toggler-icon"></span>
				</button v-else>

				<div
					class="collapse navbar-collapse flex-column ml-0 pl-3"
					id="navbarCollapse"
					v-if="!(this.moment(start) > moment())"
				>

					<ul class="navbar-nav ml-auto">

						<li
							class="nav-item mx-5"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
						>
							<router-link
								class="nav-link"
								to="/"
								v-scroll-to="{ el: '#active', offset: -100 }"
							>

								<h4 class="uppercase text-center "><span class="Regular"> promozioni </span> <br> <span class="Bold bold-size"> attive </span></h4>

							</router-link>

						</li>

						<li
							class="nav-item mx-5"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
						>
							<router-link
								class="nav-link"
								to="/"
								v-scroll-to="{ el: '#notActive', offset: -100 }"
							>
								<h4 class="uppercase text-center "><span class="Regular"> promozioni </span> <br> <span class="Bold bold-size"> concluse </span></h4>
							</router-link>

						</li>

					</ul>
				</div>

				<ul
					class="navbar-nav ml-auto"
					v-if="vw <=992 && (this.moment(start) > moment())"
				>

					<li>
						<div class="nav-link">

							<a
								href="https://www.tulipfood.it/"
								class=" black Bold underline__bold "
								target="_blank"
							> TULIPFOOD.IT</a>
						</div>
					</li>
				</ul>
			</div>
		</nav>

		<div
			class="container-fluid fixed back-1 p-4 "
			v-if="!acceptCookies && !cookieBot"
		>

			<div class="row justify-content-center">

				<div class="col-12 col-md-5 offset-md-2">

					<span class="Medium">

						Utilizziamo i cookie al fine di personalizzare e migliorare la tua
						esperienza sul nostro sito.
						<br />Visita la nostra
						<a
							style="color: red !important; text-decoration: underline"
							target="_blank"
							:href="privacyPolicy"
						>informativa sulla privacy</a>
						per acquisire maggiori informazioni. <br />Utilizzando il nostro
						sito accetti l'utilizzo dei cookie da parte nostra.
					</span>
				</div>

				<div class="col-md-4 text-center  ">
					<button
						class="btn btn-primary  pr-3 pl-3"
						v-on:click="doAcceptCookies"
					>
						OK
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
 
<script>
	import { mapGetters, mapState } from 'vuex';
	import { AUTH_LOGOUT, SET_PROMOTION, GET_PROMOTIONS } from 'actions/auth';
	import { ACCEPT_COOKIES } from 'actions/user';
	import axios from 'axios';

	import { innerWidthMixin } from '../../mixins/innerWidthMixin';
	import { pageOffsetMixin } from '../../mixins/pageOffsetMixin';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	import { checkSoftwareVersion } from '../../mixins/checkSoftwareVersion';

	const moment = require('moment');
	export default {
		name: 'navigation',
		mixins: [
			innerWidthMixin,
			pageOffsetMixin,
			sharedEnvMixin,
			checkSoftwareVersion,
		],
		data() {
			return {
				currentPage: '',
				visible: true,
				opacity: { opacity: 1 },

				moment: moment,
				// start: process.env.VUE_APP_START,
				// finish: process.env.VUE_APP_FINISH,
				// cookieBot: process.env.VUE_APP_COOKIEBOT,
				// wizard: process.env.VUE_APP_STEPFORM,
				// hasOcr: process.env.VUE_APP_HAS_OCR,
				// hasImg: process.env.VUE_APP_HAS_IMAGE,
				// hasLogin: process.env.VUE_APP_HAS_LOGIN,
				// regolamentoPolicy: process.env.VUE_APP_REGOLAMENTO,
				// privacyPolicy: process.env.VUE_APP_PRIVACY,
				// cookiePolicy: process.env.VUE_APP_COOKIE,
				// concorsoStart: false,
			};
		},
		methods: {
			// checkSoftwareVersion() {
			// 	console.log('CHECK VERSION SW');
			// 	let version = localStorage.getItem('sw_version');

			// 	if (!version) {
			// 		console.log('VERSION NOT SAVED => ' + version);
			// 		version = false;
			// 	} else {
			// 		console.log('ACTUAL VERSION => ' + version);
			// 	}
			// 	let data = {
			// 		version: version,
			// 		id_platform: process.env.VUE_APP_ID_PLATFORM,
			// 		HBGRF: process.env.VUE_APP_HBGRF,
			// 		vector_HBGRF: process.env.VUE_APP_ICODE,
			// 	};
			// 	data = this.$encryptByAES(JSON.stringify(data), process.env.VUE_APP_DOGGIE);
			// 	axios({
			// 		url: process.env.VUE_APP_ROOT_API + 'api/checkSoftwareVersion',
			// 		method: 'POST',
			// 		data: { data },
			// 		headers: {
			// 			Authorization: 'Bearer ' + process.env.VUE_APP_RABBIT,
			// 			'Content-type': 'application/json',
			// 		},
			// 	})
			// 		.then((resp) => {
			// 			if (resp.data.version) {
			// 				console.log('SERVER VERSION => ' + resp.data.version);
			// 				if (version != resp.data.version) {
			// 					localStorage.setItem('sw_version', resp.data.version);
			// 					window.location.reload();
			// 				}
			// 			}
			// 		})
			// 		.catch((error) => {});
			// },

			visibilityChanged(isVisible, entry) {},
			logout: function () {
				this.$store
					.dispatch(AUTH_LOGOUT)
					.then(() => (this.$route.path != '/' ? this.$router.push('/') : ''));
			},
			scrollToHowParticipate: function () {
				var _vue = this;
				setTimeout(function () {
					_vue.$scrollTo('#how-participate', 700, { offset: -75 });
				}, 100);
			},
			scrollToAward: function () {
				var _vue = this;
				setTimeout(function () {
					_vue.$scrollTo('#award', 700, { offset: -75 });
				}, 100);
			},
			scrollToTop: function () {
				var _vue = this;
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -125 });
				}, 100);
			},
			doAcceptCookies: function () {
				this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {
					//this.$router.push('/')
				});
			},
		},
		watch: {
			$route(to, from) {
				this.checkSoftwareVersion();
			},

			'$route.path': function (route) {
				if (route != '/') {
					this.visible = false;
					this.opacity = {
						opacity: 0,
						'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
						filter: 'alpha(opacity=0)',
					};
				} else {
					this.opacity = {
						opacity: 1,
						'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)',
						filter: 'alpha(opacity=100)',
					};
					this.visible = true;
				}

				var ttt = process.env.VUE_APP_CRYPT == 'ON' ? this.$track('route', route) : '';
			},
			acceptCookies: function () {},
		},
		computed: {
			...mapGetters([
				'getProfile',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'promoOpen',
				'getPromotions',
			]),
			...mapState({
				authLoading: (state) => state.auth.status === 'loading',
				nome: (state) => `${state.user.profile.nome}`,
			}),
			existOld: function () {
				var res = false;
				for (var x in this.getPromotions) {
					if (moment(this.getPromotions[x].data_fine) < moment()) {
						res = true;
					}
				}

				return res;
			},
			promoState: function () {
				var end = moment(this.getPromotions[this.promoOpen].data_fine);
				var now = moment();
				if (end < now) {
					return 'old';
				} else {
					return 'active';
				}
			},
		},

		updated() {
			this.currentPage = this.$route.name;
		},
		mounted: function () {
			this.checkSoftwareVersion();

			this.currentPage = this.$route.name;

			var _vue = this;
			if (this.cookieBot) {
				let cookieBotScript = document.createElement('script');
				cookieBotScript.setAttribute(
					'src',
					'https://consent.cookiebot.com/uc.js'
				);
				cookieBotScript.setAttribute('data-cbid', process.env.VUE_APP_COOKIEBOTKEY);
				cookieBotScript.setAttribute('data-blockingmode', 'auto');
				cookieBotScript.async = true;
				cookieBotScript.setAttribute('type', 'text/javascript');
				document.head.appendChild(cookieBotScript);
			}
			setTimeout(function () {
				if (_vue.$route.path != '/') {
					_vue.visible = false;
					_vue.opacity = {
						opacity: 0,
						'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
						filter: 'alpha(opacity=0)',
					};
				}
			}, 300);
		},
	};
</script>

<style lang="css" scoped>
</style>
