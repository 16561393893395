import axios from 'axios';

export const checkSoftwareVersion = {
  methods: {

    checkSoftwareVersion() {
      console.log('CHECK VERSION SW');
      let version = localStorage.getItem('sw_version');

      if (!version) {
        console.log('VERSION NOT SAVED => ' + version);
        version = false;
      } else {
        console.log('ACTUAL VERSION => ' + version);
      }
      let data = {
        version: version,
        id_platform: process.env.VUE_APP_ID_PLATFORM,
        HBGRF: process.env.VUE_APP_HBGRF,
        vector_HBGRF: process.env.VUE_APP_ICODE,
      };
      data = this.$encryptByAES(JSON.stringify(data), process.env.VUE_APP_DOGGIE);
      axios({
          url: process.env.VUE_APP_ROOT_API + 'api/checkSoftwareVersion',
          method: 'POST',
          data: {
            data
          },
          headers: {
            Authorization: 'Bearer ' + process.env.VUE_APP_RABBIT,
            'Content-type': 'application/json',
          },
        })
        .then((resp) => {
          if (resp.data.version) {
            console.log('SERVER VERSION => ' + resp.data.version);
            if (version != resp.data.version) {
              localStorage.setItem('sw_version', resp.data.version);
              window.location.reload();
            }
          }
        })
        .catch((error) => {});
    },

  },

  mounted() {
    console.log('checkSoftwareVersion')
  },

}
