export const sharedEnvMixin = {

	data() {
		return {
			start: process.env.VUE_APP_START,
			finish: process.env.VUE_APP_FINISH,

			wizard: process.env.VUE_APP_STEPFORM,
			hasOcr: process.env.VUE_APP_HAS_OCR,
			hasImg: process.env.VUE_APP_HAS_IMAGE == "ON" ? true : false,
			hasLogin: process.env.VUE_APP_HAS_LOGIN == "ON" ? true : false,

			regolamentoPolicy: process.env.VUE_APP_REGOLAMENTO,
			privacyPolicy: process.env.VUE_APP_PRIVACY,
			cookiePolicy: process.env.VUE_APP_COOKIE,
			terminiUso: process.env.VUE_APP_TERMINI_DUSO,

			cookieBot: process.env.VUE_APP_COOKIEBOT == "ON" ? true : false,

			datiSpedizione: process.env.VUE_APP_HAS_DATI_SPEDIZIONE == "ON" ? true : false,

		};
	},

}