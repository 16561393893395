export const innerWidthMixin = {

  data() {
    return {
      vw: window.innerWidth,

    };
  },


  methods: {

    handleResize: function () {

      this.vw = window.innerWidth;
      var h = window.innerHeight;

      if (this.vw > 1200 && h > 800) {
        this.stepSize = 'md';
      } else {
        this.stepSize = 'sm';
      }
    },
  },

  mounted() {

    console.log('innerWidthMixin')
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

}
